.interactive-svg-container {
    //display: inline-block;
    //position: relative;
    width: 100%;
    //padding-bottom: 100%;
    //vertical-align: bottom;
    overflow: hidden;
    //z-index:10;
}

.svg-content {
    display: block;
    position: relative;
    width: 100%;
    //top: 0;
    //left: 0;
    background-color: rgba(10, 10, 10, 0.5);
    //border:1px solid  rgba(255, 255, 255, 0.85)
}